import { DocumentType } from "../documentTypes";
import { SupportedLanguage } from "../app";
import { DocumentCategoryAnnotation } from "../configMap/interfaces/category";
import { EntityAnnotation, TableInput } from "../annotation";

export enum ExtensionType {
  PDF,
  PDF_LEGACY,
  XML,
  CSV,
  LOADING,
}

export enum EDocumentOrientation {
  LANDSCAPE,
  PORTRAIT,
  SQUARE,
}

export type MetaData = {
  language: SupportedLanguage;
  fileType: string;
  longFileType: string;
  classifiers: string;
  totalPageCount: number;
};

export type Document = {
  id: string;
  name: string;
  text: string;
  documentType: DocumentType;
  entityAnnotations?: Array<EntityAnnotation>;
  tableAnnotations?: Array<TableInput>;
  documentCategoryAnnotations: Array<DocumentCategoryAnnotation>;
  metaData: MetaData;
  documentSetInputId: string;
  pageCount: number;
};

export interface IPdfDataAction {
  pageCount: number;
}

export interface IInViewState {
  page: number;
  inView: boolean;
}
