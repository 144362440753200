import React, { FC } from "react";
import { Button, Tooltip } from "antd";
import { getInLanguageOrDefault } from "../../../../../common/utilities/language";
import CategoryVisibleValue from "../../CategoryVisibleValue";
import {
  CategorizationWithConfig,
  ICategory,
} from "../../../../../configMap/interfaces/category";
import LinkIcon from "../../LinkIcon";
import { EventTriggerType } from "../../../../interfaces/categorizationEvents";
import { Translation } from "../../../../../configMap";
import CategorizationSelect from "../CategorizationSelect";
import { DebouncedFunc } from "lodash";

type Props = {
  categorizationWithConfig: CategorizationWithConfig;
  label: Translation;
  language: string;
  selectedCategory:
    | ICategory
    | { value: string | undefined; visibleValue: string | undefined }
    | null;
  isCategorizationDisabledByParent: boolean | undefined;
  disabledParentTooltip: string;
  disableTyping: boolean;
  disabled: boolean;
  isCategorizationLoading: boolean;
  categorizationOptions: any;
  callEvents: (triggerType: EventTriggerType, value?: any) => void;
  handleChange: (key: string, callback: () => void) => Promise<void>;
  handleTypingChange: () => void;
  handleSearch: DebouncedFunc<(value: string) => Promise<void>>;
  handleFilter: (input: string, option: any) => any;
  status: "" | "warning" | "error";
  className?: string;
  tabId: string;
};

const Categorization: FC<Props> = ({
  categorizationWithConfig,
  label,
  language,
  selectedCategory,
  isCategorizationDisabledByParent,
  disabledParentTooltip,
  disableTyping,
  disabled,
  isCategorizationLoading,
  categorizationOptions,
  callEvents,
  handleChange,
  handleTypingChange,
  handleSearch,
  handleFilter,
  status,
  className,
  tabId,
}) => {
  return (
    <div className="classifier-item__container">
      <div className="classifier-item__data-wrapper">
        <LinkIcon categorization={categorizationWithConfig.categorization} />
        <div className="classifier-item__label-wrapper">
          <label>{`${getInLanguageOrDefault(label, language)} ${
            categorizationWithConfig.required ? "*" : ""
          }`}</label>
          <CategoryVisibleValue visibleValue={selectedCategory?.visibleValue} />
        </div>
      </div>
      <div className="categorization-derived__container">
        <Tooltip
          title={isCategorizationDisabledByParent && disabledParentTooltip}
        >
          <CategorizationSelect
            open={disableTyping}
            value={selectedCategory?.value}
            handleChange={handleChange}
            callEvents={callEvents}
            onFocus={handleTypingChange}
            onBlur={handleTypingChange}
            onSearch={handleSearch}
            filterOption={handleFilter}
            disabled={disabled || isCategorizationDisabledByParent}
            tabId={tabId}
            categorizationOptions={categorizationOptions}
            loading={isCategorizationLoading}
            status={status}
            className={`${className} categorization-derived__select`}
          />
        </Tooltip>
        <Button
          disabled={disabled}
          onClick={() => !disabled && callEvents(EventTriggerType.ON_CLICK)}
          className={`categorization-derived__button ${
            disabled ? "disabled" : ""
          }`}
          icon={<i className="bi bi-arrow-clockwise" />}
        />
      </div>
    </div>
  );
};

export default Categorization;
