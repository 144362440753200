import { useAppDispatch, useAppSelector } from "../../app";
import { useCallback, useMemo } from "react";
import { ICategorization } from "../../configMap/interfaces/category";
import { KeyByAny } from "../../documentSet/interfaces/overview";
import {
  CategorizationEvent,
  EventCategorizationResponse,
  EventParam,
  EventTriggerType,
} from "../interfaces/categorizationEvents";
import selectAnnotationReducer from "../../annotation/selectors/annotationReducerSelector";
import { useLazyCallEventQuery } from "../store/metadataApi";
import {
  addLoadingCategorizationIds,
  removeLoadingCategorizationIds,
} from "../store/metadataSlice";
import { notification } from "antd";
import {
  createDcaFromResponse,
  getParamValue,
} from "../utils/categorizationEvents";

const useCategorizationEvents = (
  categorization: ICategorization,
  documentId: string | undefined,
  attribute: KeyByAny | undefined
) => {
  const dispatch = useAppDispatch();

  const events = categorization.events;

  const loadingCategorizationIds = useAppSelector(
    (state) => state.metadataReducer.loadingCategorizationIds
  );

  const entityAnnotations = useAppSelector(selectAnnotationReducer);

  const documentCategoryAnnotations = useAppSelector(
    (state) =>
      state.metadataReducer.categorizationState.documentCategoryAnnotations
  );

  const [callEvent] = useLazyCallEventQuery();

  const isCategorizationLoading = useMemo(() => {
    return loadingCategorizationIds.includes(categorization.id);
  }, [loadingCategorizationIds, categorization.id]);

  const setLoadingCategorizations = useCallback(
    (
      isLoading: boolean,
      responseProperties: Array<EventCategorizationResponse>
    ) => {
      const categorizationIds = responseProperties.map((rp) =>
        rp.categorizationId.toLowerCase()
      );

      if (isLoading) {
        dispatch(addLoadingCategorizationIds(categorizationIds));
      } else {
        dispatch(removeLoadingCategorizationIds(categorizationIds));
      }
    },
    [dispatch]
  );

  const getBodyParams = useCallback(
    (params: Array<EventParam> | undefined, value: string): KeyByAny => {
      const bodyParameters: KeyByAny = {};
      if (!params) {
        return bodyParameters;
      }

      for (const param of params) {
        if (!param?.property) {
          continue;
        }

        const paramValue = getParamValue(
          param,
          value,
          categorization.id,
          documentCategoryAnnotations,
          entityAnnotations,
          attribute
        );
        if (paramValue) {
          bodyParameters[param.property] = paramValue;
        }
      }

      return bodyParameters;
    },
    [
      categorization.id,
      documentCategoryAnnotations,
      entityAnnotations,
      attribute,
    ]
  );

  const getQueryParams = useCallback(
    (params: Array<EventParam> | undefined, value: string): string => {
      if (!params) {
        return "";
      }

      const result = params
        .map((param) => {
          const paramValue = getParamValue(
            param,
            value,
            categorization.id,
            documentCategoryAnnotations,
            entityAnnotations,
            attribute
          );
          return paramValue !== null ? `${param.property}=${paramValue}` : null;
        })
        .filter((param) => param !== null)
        .join("&");
      return `?${result}`;
    },
    [
      categorization.id,
      documentCategoryAnnotations,
      entityAnnotations,
      attribute,
    ]
  );

  const triggerEvent = useCallback(
    async (event: CategorizationEvent, value: string) => {
      const { request, responseProperties, triggerType } = event;
      const { urlParam, bodyParams, queryParams, httpMethod, endpoint } =
        request;

      if (!endpoint || !triggerType || !httpMethod || !responseProperties) {
        return;
      }

      let urlParamValue =
        getParamValue(
          urlParam,
          value,
          categorization.id,
          documentCategoryAnnotations,
          entityAnnotations,
          attribute
        ) ?? "";
      if (urlParamValue) {
        urlParamValue = `/${urlParamValue}`;
      }

      const bodyParameters = getBodyParams(bodyParams, value);
      const queryParameters = getQueryParams(queryParams, value);

      const body = {
        endpoint: `${endpoint}${urlParamValue}${queryParameters}`,
        httpMethod,
        bodyParams: bodyParameters,
      };

      try {
        setLoadingCategorizations(true, responseProperties);
        const response = await callEvent(body).unwrap();
        createDcaFromResponse(
          responseProperties,
          response,
          dispatch,
          documentId!,
          categorization.id,
          categorization.categories[0].id
        );
      } catch (error: any) {
        const message =
          error?.data?.error ||
          "We encountered an issue while fetching data for your selection.";
        notification.error({ message });
      } finally {
        setLoadingCategorizations(false, responseProperties);
      }
    },
    [
      getBodyParams,
      getQueryParams,
      callEvent,
      dispatch,
      setLoadingCategorizations,
      categorization.id,
      documentCategoryAnnotations,
      entityAnnotations,
      attribute,
      categorization.categories,
      documentId,
    ]
  );

  const callEvents = useCallback(
    async (eventTriggerType: EventTriggerType, value: string = "") => {
      if (!events) {
        return;
      }

      // Filter the events to include only those with the specified trigger type
      const filteredEvents = events.filter(
        (event) => event.triggerType === eventTriggerType
      );

      await Promise.all(
        filteredEvents.map((event) => triggerEvent(event, value))
      );
    },
    [events, triggerEvent]
  );

  return {
    callEvents,
    isCategorizationLoading,
  };
};

export default useCategorizationEvents;
