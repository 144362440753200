import { useCallback, useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../app";
import entityChildrenSelector from "../selectors/entityChildrenSelector";
import { EntityTypeRelation } from "../interfaces/entity";
import { useGetNormalizedAnnotationBatchMutation } from "../store/configMapApi";
import {
  setIsLoadingAnnotations,
  updateAnnotationNormalizationInState,
} from "../../annotation/store/annotationSlice";
import {
  EntityAnnotationBatchToBeNormalizedDto,
  EntityAnnotationDto,
} from "../../annotation/interfaces/annotation";
import { notification } from "antd";
import flatEntityListSelector from "../selectors/flatEntityListSelector";
import { useTranslation } from "react-i18next";

const useEntityParentNormalization = (
  childrenIds: Array<EntityTypeRelation> | null,
  entityAnnotationValue: string
) => {
  const { t } = useTranslation("annotations");
  const dispatch = useAppDispatch();

  const previousValue = useRef("");
  const isRunning = useRef(false);

  const entities = useAppSelector(flatEntityListSelector);
  const childrenAnnotations = useAppSelector((state) =>
    entityChildrenSelector(state, { childrenIds })
  );

  const metaData = useAppSelector(
    (state) => state.documentReducer.activeDocument?.metaData
  );

  const [getNormalizedAnnotationBatch] =
    useGetNormalizedAnnotationBatchMutation();

  const updateEntityChildren = useCallback(async () => {
    if (entityAnnotationValue === previousValue.current) {
      return;
    }

    if (!childrenAnnotations.length) {
      previousValue.current = entityAnnotationValue;
      return;
    }

    if (!entityAnnotationValue) {
      previousValue.current = entityAnnotationValue;
      return;
    }

    const toUpdateIds = childrenAnnotations.map((ea) => ea.id);

    try {
      isRunning.current = true;
      const entityAnnotationsBody: EntityAnnotationBatchToBeNormalizedDto = {
        entityAnnotations: childrenAnnotations.map(
          (a): EntityAnnotationDto => ({
            id: a.id,
            value: a.values.join(" "),
            index: a.index || 1,
            entityNormalizations: a.entity.entityNormalizations || [],
            entityTypeId: a.entity.id,
            multipleGroupBlocks: a.entity.multipleGroupBlocks || false,
            parentValue: entityAnnotationValue,
          })
        ),
        metaData: metaData,
      };

      dispatch(
        setIsLoadingAnnotations({
          ids: toUpdateIds,
          isLoading: true,
        })
      );

      const found = entities.find(
        (e) =>
          e.entityType.id.toLowerCase() ===
          childrenAnnotations[0].entity.id.toLowerCase()
      );

      const url =
        (found?.entityType?.entityNormalizations || [])[0]
          ?.normalizationStrategy?.urlBatch || null;

      const normalizedAnnotations = await getNormalizedAnnotationBatch({
        url,
        entityAnnotationsDto: entityAnnotationsBody,
      }).unwrap();

      normalizedAnnotations.forEach((normalizedAnnotation) => {
        if (!normalizedAnnotation.id) {
          return;
        }

        const multipleGroupBlocks = childrenAnnotations.find(
          (a) => a.id === normalizedAnnotation.id!
        )!.multipleGroupBlocks;

        dispatch(
          updateAnnotationNormalizationInState({
            id: normalizedAnnotation.id,
            newValue: normalizedAnnotation.normalizedValue,
            method: normalizedAnnotation.normalizedMethod,
            options: normalizedAnnotation.options,
            multipleGroupBlocks: multipleGroupBlocks,
            isByUser: true,
            errorType: normalizedAnnotation.errorType,
          })
        );
      });
      previousValue.current = entityAnnotationValue;
    } catch (error) {
      notification.error({
        message: t("normalizationError"),
      });
      return;
    } finally {
      isRunning.current = false;
      previousValue.current = entityAnnotationValue;
      dispatch(
        setIsLoadingAnnotations({
          ids: toUpdateIds,
          isLoading: false,
        })
      );
    }
  }, [
    t,
    dispatch,
    childrenAnnotations,
    entityAnnotationValue,
    metaData,
    getNormalizedAnnotationBatch,
    entities,
  ]);

  useEffect(() => {
    if (isRunning.current) {
      return;
    }

    updateEntityChildren();
  }, [updateEntityChildren]);
};

export default useEntityParentNormalization;
