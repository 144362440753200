import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Document } from "../";
import { IPDFDocument, IPdfPageData } from "../../annotator/interfaces/pdf";
import { generateRandomHash } from "../../annotator/helpers/hashHelper";
import { IInViewState, IPdfDataAction } from "../interfaces";
import {
  recalculateDimensions,
  scalePDFPageTokens,
} from "../../annotation/utils/scaling";
import { IPDFRescaleAction } from "../interfaces/actions";

interface DocumentState {
  activeDocument?: Document;
  pdf: IPDFDocument | null;
  pdfDataLoading: boolean;
  pagesInView: Array<IInViewState>;
}

const initialState: DocumentState = {
  activeDocument: undefined,
  pdf: null,
  pdfDataLoading: false,
  pagesInView: [],
};

export const documentSlice = createSlice({
  name: "document",
  initialState,
  reducers: {
    updateActiveDocument: (
      state,
      action: PayloadAction<Document | undefined>
    ) => {
      state.activeDocument = action.payload;
    },
    setPdfData: (state, action: PayloadAction<IPDFDocument | null>) => {
      state.pdf = action.payload;
    },
    setInitialDataForPdf: (state, action: PayloadAction<IPdfDataAction>) => {
      const { pageCount } = action.payload;

      state.pagesInView = Array(pageCount)
        .fill(0)
        .map((_, i) => ({
          page: i + 1,
          inView: false,
        }));

      state.pdf = {
        metadata: {
          pageCount,
        },
        pages: Array(pageCount)
          .fill(0)
          .map((_, i) => ({
            id: generateRandomHash(false),
            page: i + 1,
            dimension: {
              unit: "pixels",
              width: 0,
              height: 0,
            },
            tokens: [],
            image: null,
            text: "",
          })),
      };
    },
    addPageToPDFData: (state, action: PayloadAction<IPdfPageData>) => {
      if (!state.pdf) {
        return;
      }

      const { payload } = action;
      const {
        pdf: { pages },
      } = state;

      state.pdf.pages = [...pages]
        .map((p) => {
          if (p.page === payload.page) {
            return {
              ...payload,
              id: p.id,
            };
          }
          return p;
        })
        .sort((a, b) => a.page - b.page);
    },
    removePageDataFromPdf: (state, action: PayloadAction<number>) => {
      if (!state.pdf) {
        return;
      }

      const { payload } = action;
      const {
        pdf: { pages },
      } = state;

      state.pdf.pages = [...pages].map((p) => {
        if (p.page === payload) {
          return {
            ...p,
            tokens: [],
            image: null,
          };
        }

        return p;
      });
    },
    rescalePdfPages: (state, action: PayloadAction<IPDFRescaleAction>) => {
      if (!state.pdf) {
        return;
      }

      const { oldScale, newScale } = action.payload;

      const {
        pdf: { pages },
      } = state;

      state.pdf.pages = pages.map((p) => {
        const dimension = recalculateDimensions(
          p.dimension,
          oldScale,
          newScale
        );
        const tokens = scalePDFPageTokens(p.tokens, oldScale, newScale);

        return {
          ...p,
          dimension,
          tokens,
        };
      });
    },
    setPdfDataLoading(state, action: PayloadAction<boolean>) {
      state.pdfDataLoading = action.payload;
    },
    changePageInView(state, action: PayloadAction<IInViewState>) {
      const pageData = action.payload;

      state.pagesInView = [...state.pagesInView].map((p) => {
        if (pageData.page === p.page) {
          return pageData;
        }

        return p;
      });
    },
    resetDocumentReducer: () => initialState,
  },
});

export const {
  updateActiveDocument,
  setPdfData,
  setInitialDataForPdf,
  addPageToPDFData,
  removePageDataFromPdf,
  rescalePdfPages,
  setPdfDataLoading,
  changePageInView,
  resetDocumentReducer,
} = documentSlice.actions;

export default documentSlice.reducer;
