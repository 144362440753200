import React, { FC, useMemo } from "react";
import "./Classifiers.scss";
import useCollapsable from "../../../common/utilities/hooks/useCollapsable";
import { CategorizationWithConfig } from "../../../configMap/interfaces/category";
import Categorization from "./Categorization";

type Props = {
  disabled: boolean;
  categorizationsWithConfig?: Array<CategorizationWithConfig>;
  groupBlockId?: string;
  index?: number;
  showHeader: boolean;
};

const CategorizationBlock: FC<Props> = ({
  disabled,
  categorizationsWithConfig,
  showHeader,
  groupBlockId,
  index = 1,
}) => {
  const { handleClick, style, open } = useCollapsable();

  const sortedCategorizationsWithConfig = useMemo(() => {
    if (!categorizationsWithConfig?.length) {
      return [];
    }

    return [...categorizationsWithConfig].sort(
      (a, b) =>
        (a.categorization.index ?? Number.MAX_VALUE) -
        (b.categorization.index ?? Number.MAX_VALUE)
    );
  }, [categorizationsWithConfig]);

  if (!categorizationsWithConfig?.length) {
    return null;
  }

  return (
    <div className="classifier-block__container">
      {showHeader ? (
        <div
          className="classifier-block__header"
          style={open ? { marginBottom: "20px" } : { marginBottom: 0 }}
        >
          <h1>Enrichment values</h1>
          {open ? (
            <i className="bi bi-arrow-up-circle" onClick={handleClick} />
          ) : (
            <i className="bi bi-arrow-down-circle" onClick={handleClick} />
          )}
        </div>
      ) : null}
      <div
        className={`classifier-block__items ${open ? "open" : ""}`.trim()}
        style={style}
      >
        {sortedCategorizationsWithConfig.map((categorizationWithConfig) => (
          <Categorization
            key={categorizationWithConfig.categorization.id}
            disabled={disabled}
            categorizationWithConfig={categorizationWithConfig}
            groupBlockId={groupBlockId}
            index={index}
          />
        ))}
      </div>
    </div>
  );
};

export default CategorizationBlock;
