import React, { FC } from "react";
import {
  CategorizationSetType,
  CategorizationWithConfig,
  ICategory,
} from "../../../../configMap/interfaces/category";
import CategorizationDynamicOrFixed from "./CategorizationDynamicOrFixed/CategorizationDynamicOrFixed";
import CategorizationDerived from "./CategorizationDerived/CategorizationDerived";
import { Translation } from "../../../../configMap";
import { EventTriggerType } from "../../../interfaces/categorizationEvents";
import { OptionType } from "../../../interfaces/classifier";
import { DebouncedFunc } from "lodash";

export type CategorizationSwitchProps = {
  categorizationWithConfig: CategorizationWithConfig;
  label: Translation;
  language: string;
  selectedCategory:
    | ICategory
    | { value: string | undefined; visibleValue: string | undefined }
    | null;
  isCategorizationDisabledByParent: boolean | undefined;
  disabledParentTooltip: string;
  disableTyping: boolean;
  disabled: boolean;
  isCategorizationLoading: boolean;
  categorizationOptions: Array<OptionType>;
  callEvents: (triggerType: EventTriggerType, value?: any) => void;
  handleChange: (key: string, callback: () => void) => Promise<void>;
  handleTypingChange: () => void;
  handleSearch: DebouncedFunc<(value: string) => Promise<void>>;
  handleFilter: (input: string, option: any) => any;
  status: "" | "warning" | "error";
  className?: string;
  tabId: string;
};

const CategorizationSwitch: FC<CategorizationSwitchProps> = (props) => {
  switch (props.categorizationWithConfig.categorization.setType) {
    case CategorizationSetType.DERIVED:
      // Categorization that retrieves data from another event
      return <CategorizationDerived {...props} />;
    default:
      return <CategorizationDynamicOrFixed {...props} />;
  }
};

export default CategorizationSwitch;
