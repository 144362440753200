import React, { FC, ReactNode, useEffect, useRef } from "react";
import { Select } from "antd";
import { OptionType } from "../../../interfaces/classifier";
import { BaseSelectRef } from "rc-select";
import { useAppSelector } from "../../../../app";
import { EventTriggerType } from "../../../interfaces/categorizationEvents";

type Props = {
  open: boolean;
  value: string | undefined;
  onFocus: any;
  onBlur: any;
  onSearch: any;
  filterOption: (input: string, option: any) => boolean;
  notFoundContent?: ReactNode;
  disabled?: boolean;
  className?: string;
  status?: "" | "warning" | "error" | undefined;
  loading?: boolean;
  tabId: string;
  categorizationOptions: Array<OptionType>;
  handleChange: (key: string, callback: () => void) => Promise<void>;
  callEvents: any;
};

const { Option } = Select;

const mapping = { value: "key" };

const CategorizationSelect: FC<Props> = ({
  open,
  value,
  onFocus,
  onBlur,
  onSearch,
  filterOption,
  notFoundContent,
  disabled,
  className,
  status,
  loading,
  tabId,
  categorizationOptions,
  handleChange,
  callEvents,
}) => {
  const ref = useRef<BaseSelectRef | null>(null);

  const { activeButton, isAnnotating } = useAppSelector(
    (state) => state.appReducer
  );

  useEffect(() => {
    if (!activeButton || !ref.current) {
      return;
    }

    if (activeButton.id !== tabId) {
      return;
    }

    ref.current!.focus();
  }, [activeButton, tabId]);

  useEffect(() => {
    if (isAnnotating) {
      ref.current!.blur();
    }
  }, [isAnnotating]);

  return (
    <Select
      open={open}
      ref={ref}
      allowClear
      showSearch
      value={value}
      placeholder="Select a value"
      optionFilterProp="children"
      onChange={(value: any, option: any) => {
        const callback = () => {
          ref.current!.blur();
          if (option?.value) {
            callEvents(EventTriggerType.ON_SELECT, option.value);
          }
        };
        handleChange(value, callback);
      }}
      onFocus={onFocus}
      onBlur={onBlur}
      onSearch={onSearch}
      filterOption={filterOption}
      notFoundContent={notFoundContent}
      fieldNames={mapping}
      disabled={disabled}
      className={className}
      status={status}
      loading={loading}
    >
      {categorizationOptions.map((o: OptionType) => {
        const { label, value, key } = o;
        const split = label.split(/\n/);

        if (split.length > 1) {
          const height = split.length * 25;
          return (
            <Option
              key={key}
              value={value}
              label={label}
              style={{ height: `${height}px` }}
            >
              <ul>
                {split.map((s) => (
                  <li key={s}>{s}</li>
                ))}
              </ul>
            </Option>
          );
        }

        return (
          <Option key={key} value={value} label={label}>
            {label}
          </Option>
        );
      })}
    </Select>
  );
};

export default CategorizationSelect;
