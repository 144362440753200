import { configureStore } from "@reduxjs/toolkit";
import AppReducer from "./store/appSlice";
import { analyticsReducer } from "../analytics";
import { AnnotationReducer } from "../annotation";
import { ConfigMapReducer } from "../configMap";
import { dataRefiningReducer } from "../common/dataRefining/";
import { DocumentReducer } from "../document";
import { documentSetsReducer } from "../documentSet";
import { userReducer } from "../user";
import { documentTypesReducer } from "../documentTypes";
import { userManagementReducer } from "../userManagement";
import { metadataReducer } from "../metadata";
import { emailTemplatesReducer } from "../emailTemplates";
import { tourReducer } from "../tours";
import { annotatorReducer } from "../annotator";
import { organizationReducer } from "../organization";
import { annotationTableControlsReducer } from "../annotationTableControls";
import Sentry from "../sentry";
import { userManagementApi } from "../userManagement/store/UserManagementApi";
import { emailTemplatesApi } from "../emailTemplates/store/emailTemplatesApi";
import { analyticsApi } from "../analytics/store/analyticsApi";
import { appApi } from "./store/appApi";
import { documentSetApi } from "../documentSet/store/documentSetApi";
import { documentApi } from "../document/store/documentApi";
import { configMapApi } from "../configMap/store/configMapApi";
import { documentTypeApi } from "../documentTypes/store/documentTypeApi";
import { annotationSideControlsApi } from "../annotationSideControls/store/annotationSideControlsApi";
import { userApi } from "../user/store/userApi";
import { organizationApi } from "../organization/store/organizationApi";
import { annotationSideControlsReducer } from "../annotationSideControls";
import { metadataApi } from "../metadata/store/metadataApi";

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  stateTransformer: (state) => {
    return {
      ...state,
      giganticState: null,
    };
  },
});

export const store = configureStore({
  reducer: {
    analyticsReducer: analyticsReducer,
    appReducer: AppReducer,
    annotatorReducer: annotatorReducer,
    annotationReducer: AnnotationReducer,
    configMapReducer: ConfigMapReducer,
    dataRefiningReducer: dataRefiningReducer,
    documentReducer: DocumentReducer,
    documentTypesReducer: documentTypesReducer,
    documentSetsReducer: documentSetsReducer,
    emailTemplatesReducer: emailTemplatesReducer,
    metadataReducer: metadataReducer,
    organizationReducer: organizationReducer,
    userReducer: userReducer,
    userManagementReducer: userManagementReducer,
    tourReducer: tourReducer,
    annotationTableControlsReducer: annotationTableControlsReducer,
    annotationSideControlsReducer: annotationSideControlsReducer,
    [analyticsApi.reducerPath]: analyticsApi.reducer,
    [appApi.reducerPath]: appApi.reducer,
    [annotationSideControlsApi.reducerPath]: annotationSideControlsApi.reducer,
    [configMapApi.reducerPath]: configMapApi.reducer,
    [documentApi.reducerPath]: documentApi.reducer,
    [documentSetApi.reducerPath]: documentSetApi.reducer,
    [documentTypeApi.reducerPath]: documentTypeApi.reducer,
    [emailTemplatesApi.reducerPath]: emailTemplatesApi.reducer,
    [organizationApi.reducerPath]: organizationApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [userManagementApi.reducerPath]: userManagementApi.reducer,
    [metadataApi.reducerPath]: metadataApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false })
      .concat(analyticsApi.middleware)
      .concat(appApi.middleware)
      .concat(annotationSideControlsApi.middleware)
      .concat(configMapApi.middleware)
      .concat(documentApi.middleware)
      .concat(documentSetApi.middleware)
      .concat(documentTypeApi.middleware)
      .concat(organizationApi.middleware)
      .concat(userApi.middleware)
      .concat(emailTemplatesApi.middleware)
      .concat(userManagementApi.middleware)
      .concat(metadataApi.middleware),
  enhancers: [sentryReduxEnhancer],
  devTools: { trace: true, traceLimit: 50 },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
