import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getVariableFromEnv } from "../../common/utilities/env";
import { getHeadersWithAuthorization } from "../../common/reduxQuery/helpers";
import { EventBody, EventHttpMethod } from "../interfaces/categorizationEvents";

const API_BASE_URL = getVariableFromEnv("API_BASE_URL");

export const metadataApi = createApi({
  reducerPath: "metadataApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_BASE_URL,
    prepareHeaders: (headers) => getHeadersWithAuthorization(headers),
  }),
  endpoints: (build) => ({
    callEvent: build.query<any, EventBody>({
      query: ({ endpoint, bodyParams, httpMethod }) => {
        if (httpMethod === EventHttpMethod.GET) {
          return {
            url: `/${endpoint}`,
            method: httpMethod,
          };
        }

        return {
          url: `/${endpoint}`,
          method: httpMethod,
          body: bodyParams,
        };
      },
    }),
  }),
});

export const { useLazyCallEventQuery } = metadataApi;
