import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  DocumentAnnotation,
  DocumentAnnotations,
  RemoveAnnotationPayload,
  RemoveAnnotationsForEntitiesPayload,
  UpdateMultiGroupBlockValuePayload,
  UpdateAnnotation,
  UpdateAnnotationNormalization,
  SetIsLoadingAnnotationPayload,
  SetIsLoadingAnnotationsPayload,
  HighlightedEntity,
} from "../interfaces/annotation";
import { TableInput, UpdateTablesPayload } from "../interfaces/table";
import { Annotation } from "../../annotator/interfaces/annotation";
import { baseScale } from "../constants";
import {
  flattenGroupedOutput,
  getAnnotationsInReducer,
  getSideAndTableControlAnnotations,
  groupAnnotations,
} from "../utils/utils";

export interface AnnotationState {
  isDragging: boolean;
  scale: number;
  sideControlAnnotations: Array<Annotation>;
  tableControlAnnotations: Array<Annotation>;
  tableAnnotations: Array<TableInput>;
  highlightedEntities: Array<HighlightedEntity>;
  lastAnnotation: Annotation | null;
}

const initialState: AnnotationState = {
  isDragging: false,
  scale: baseScale,
  sideControlAnnotations: [],
  tableControlAnnotations: [],
  tableAnnotations: [],
  highlightedEntities: [],
  lastAnnotation: null,
};

export const annotationSlice = createSlice({
  name: "annotation",
  initialState,
  reducers: {
    addAnnotationsForDocument: (
      state,
      action: PayloadAction<DocumentAnnotations>
    ) => {
      const { annotations } = action.payload;

      const { sideControlAnnotations, tableControlAnnotations } =
        getSideAndTableControlAnnotations(annotations);

      state.sideControlAnnotations = sideControlAnnotations;
      state.tableControlAnnotations = tableControlAnnotations;
    },
    addAnnotationToState: (
      state,
      action: PayloadAction<DocumentAnnotation>
    ) => {
      const { annotation, multipleGroupBlocks } = action.payload;
      const annotations = getAnnotationsInReducer(state, multipleGroupBlocks);

      const newAnnotations = [...annotations, annotation];
      const grouped = groupAnnotations(newAnnotations);
      const convertedAnnotations = flattenGroupedOutput(grouped);

      state.lastAnnotation = annotation;
      if (multipleGroupBlocks) {
        state.tableControlAnnotations = convertedAnnotations;
      } else {
        state.sideControlAnnotations = convertedAnnotations;
      }
    },
    updateAnnotationInState: (
      state,
      action: PayloadAction<UpdateAnnotation>
    ) => {
      const {
        annotation: annotationToUpdate,
        method,
        newValue,
        options,
        errorMessage,
        multipleGroupBlocks,
        isByUser,
        errorType,
      } = action.payload;

      const annotations = getAnnotationsInReducer(state, multipleGroupBlocks);

      const convertedAnnotations = [...annotations].map((annotation) => {
        if (annotation.id === annotationToUpdate.id) {
          annotation.entityAnnotationNormalization = {
            ...annotation.entityAnnotationNormalization,
            normalizedMethod: method,
            normalizedValue: newValue,
            options,
            errorMessage,
            isByUser,
            errorType,
          };
        }
        return annotation;
      });

      state.lastAnnotation = annotationToUpdate;

      if (multipleGroupBlocks) {
        state.tableControlAnnotations = convertedAnnotations;
      } else {
        state.sideControlAnnotations = convertedAnnotations;
      }
    },
    updateAnnotationNormalizationInState: (
      state,
      action: PayloadAction<UpdateAnnotationNormalization>
    ) => {
      const {
        id: idToUpdate,
        method,
        newValue,
        options,
        errorMessage,
        multipleGroupBlocks,
        isByUser,
        errorType,
      } = action.payload;

      const annotations = getAnnotationsInReducer(state, multipleGroupBlocks);

      const convertedAnnotations = [...annotations].map((annotation) => {
        if (annotation.id === idToUpdate) {
          annotation.entityAnnotationNormalization = {
            ...annotation.entityAnnotationNormalization,
            normalizedMethod: method,
            normalizedValue: newValue,
            options,
            errorMessage,
            isByUser,
            errorType,
          };
        }
        return annotation;
      });

      if (multipleGroupBlocks) {
        state.tableControlAnnotations = convertedAnnotations;
      } else {
        state.sideControlAnnotations = convertedAnnotations;
      }
    },
    updateAnnotationsBatch: (
      state,
      action: PayloadAction<DocumentAnnotations>
    ) => {
      const { annotations, documentId, multipleGroupBlocks } = action.payload;

      const annotationsInReducer = getAnnotationsInReducer(
        state,
        multipleGroupBlocks!
      );

      if (!annotationsInReducer) {
        if (multipleGroupBlocks) {
          state.tableControlAnnotations = annotations;
        } else {
          state.sideControlAnnotations = annotations;
        }
        return;
      }

      const updatedIds = (annotations || []).map((annotation) => annotation.id);

      const newAnnotations = [
        ...annotationsInReducer
          .filter((annotation) => !updatedIds.includes(annotation.id))
          .concat(annotations)
          .map((annotation) => ({
            ...annotation,
            documentId: documentId,
            index: annotation.index,
            modelScore: annotation.modelScore || 100,
            isByUser: annotation.isByUser,
            triggerNormalization: false,
          }))
          .filter(
            (annotation) => !annotation.id || updatedIds.includes(annotation.id)
          ),
      ];

      const grouped = groupAnnotations(newAnnotations);

      const convertedAnnotations = [
        ...annotationsInReducer.filter((ea) => ea.documentId !== documentId),
        ...flattenGroupedOutput(grouped),
      ].filter(
        (annotation, index, self) =>
          index === self.findIndex((a) => a.id === annotation.id)
      );

      state.lastAnnotation =
        convertedAnnotations[convertedAnnotations.length - 1];

      if (multipleGroupBlocks) {
        state.tableControlAnnotations = convertedAnnotations;
      } else {
        state.sideControlAnnotations = convertedAnnotations;
      }
    },
    removeAnnotationFromState: (
      state,
      action: PayloadAction<RemoveAnnotationPayload>
    ) => {
      const annotations = getAnnotationsInReducer(
        state,
        action.payload.multipleGroupBlocks
      );

      const convertedAnnotations = [...annotations].filter(
        (annotation) => annotation.id !== action.payload.annotationId
      );

      if (action.payload.multipleGroupBlocks) {
        state.tableControlAnnotations = convertedAnnotations;
      } else {
        state.sideControlAnnotations = convertedAnnotations;
      }
    },
    removeAnnotationsForEntitiesByGroupBlockIndex: (
      state,
      action: PayloadAction<RemoveAnnotationsForEntitiesPayload>
    ) => {
      const {
        groupBlockIndex,
        entityIds,
        isGroupRemoved,
        multipleGroupBlocks,
      } = action.payload;

      const annotations = getAnnotationsInReducer(state, multipleGroupBlocks);

      const convertedAnnotations = [...annotations]
        .filter(
          (annotation) =>
            !(
              entityIds.includes(annotation.entity.id) &&
              annotation.index === groupBlockIndex
            )
        )
        .map((annotation) => {
          if (!isGroupRemoved) {
            return annotation;
          }

          if (
            entityIds.includes(annotation.entity.id) &&
            annotation.index! > groupBlockIndex
          ) {
            return {
              ...annotation,
              index: annotation.index! - 1,
            };
          }
          return annotation;
        });

      if (multipleGroupBlocks) {
        state.tableControlAnnotations = convertedAnnotations;
      } else {
        state.sideControlAnnotations = convertedAnnotations;
      }
    },
    removeAllAnnotationsForDocument: (state, action: PayloadAction<string>) => {
      state.sideControlAnnotations = [
        ...state.sideControlAnnotations.filter(
          (ea) => ea.documentId !== action.payload
        ),
      ];
      state.tableControlAnnotations = [
        ...state.tableControlAnnotations.filter(
          (ea) => ea.documentId !== action.payload
        ),
      ];
    },
    updateTableAnnotationsInState: (
      state,
      action: PayloadAction<UpdateTablesPayload>
    ) => {
      const { documentId, tables } = action.payload;
      const scale = baseScale;

      state.tableAnnotations = tables.map((table) => ({
        id: table.id.includes("NEW") ? null : table.id,
        documentId: documentId,
        x: Math.round((table.x / scale) * baseScale),
        y: Math.round((table.y / scale) * baseScale),
        width: Math.round((table.width / scale) * baseScale),
        height: Math.round((table.height / scale) * baseScale),
        linkedTables: table.linkedTables,
        page: table.page,
        rows: table.rows
          .map((row) => ({
            id: row.id.includes("NEW") ? null : row.id,
            x: Math.round((row.y / scale) * baseScale),
            excluded: row.exclude,
          }))
          .sort((a, b) => a.x - b.x),
        columns: table.columns
          .map((column) => ({
            id: column.id.includes("NEW") ? null : column.id,
            y: Math.round((column.x / scale) * baseScale),
          }))
          .sort((a, b) => a.y - b.y),
      }));
    },
    setTableAnnotations: (state, action: PayloadAction<Array<TableInput>>) => {
      state.tableAnnotations = action.payload;
    },
    changeScale: (state, action: PayloadAction<number>) => {
      state.scale = action.payload;
    },
    resetAnnotationReducer: () => initialState,
    updateGroupBlockAnnotations: (
      state,
      action: PayloadAction<UpdateMultiGroupBlockValuePayload>
    ) => {
      const { groupBlockIndex, entityIds, addBefore, multipleGroupBlocks } =
        action.payload;

      const annotations = getAnnotationsInReducer(
        state,
        action.payload.multipleGroupBlocks
      );

      const convertedAnnotations = [...annotations].map((annotation) => {
        if (!annotation.index) {
          return annotation;
        }
        if (
          addBefore &&
          annotation.index - 1 === 0 &&
          entityIds.includes(annotation.entity.id)
        ) {
          return { ...annotation, index: annotation.index + 1 };
        }
        if (
          annotation.index - 1 > groupBlockIndex &&
          entityIds.includes(annotation.entity.id)
        ) {
          return { ...annotation, index: annotation.index + 1 };
        }
        return annotation;
      });

      if (multipleGroupBlocks) {
        state.tableControlAnnotations = convertedAnnotations;
      } else {
        state.sideControlAnnotations = convertedAnnotations;
      }
    },
    removeAnnotationsForEntity: (
      state,
      action: PayloadAction<RemoveAnnotationsForEntitiesPayload>
    ) => {
      const { entityIds, multipleGroupBlocks } = action.payload;

      const annotations = getAnnotationsInReducer(
        state,
        action.payload.multipleGroupBlocks
      );

      const convertedAnnotations = [...annotations].filter(
        (annotation) => !entityIds.includes(annotation.entity.id)
      );

      if (multipleGroupBlocks) {
        state.tableControlAnnotations = convertedAnnotations;
      } else {
        state.sideControlAnnotations = convertedAnnotations;
      }
    },
    setIsLoadingAnnotation: (
      state,
      action: PayloadAction<SetIsLoadingAnnotationPayload>
    ) => {
      const { id: idToUpdate, isLoading, multipleGroupBlocks } = action.payload;

      const annotations = getAnnotationsInReducer(
        state,
        action.payload.multipleGroupBlocks
      );

      const convertedAnnotations = [...annotations].map((annotation) => {
        if (annotation.id === idToUpdate) {
          annotation.isLoading = isLoading;
        }
        return annotation;
      });

      if (multipleGroupBlocks) {
        state.tableControlAnnotations = convertedAnnotations;
      } else {
        state.sideControlAnnotations = convertedAnnotations;
      }
    },
    setIsLoadingAnnotations: (
      state,
      action: PayloadAction<SetIsLoadingAnnotationsPayload>
    ) => {
      const { ids, isLoading } = action.payload;

      state.tableControlAnnotations = state.tableControlAnnotations.map(
        (annotation) => {
          if (ids.includes(annotation.id)) {
            annotation.isLoading = isLoading;
          }
          return annotation;
        }
      );
      state.sideControlAnnotations = [...state.sideControlAnnotations].map(
        (annotation) => {
          if (ids.includes(annotation.id)) {
            annotation.isLoading = isLoading;
          }
          return annotation;
        }
      );
    },
    setIsDragging: (state, action: PayloadAction<boolean>) => {
      state.isDragging = action.payload;
    },
    setHighlightedEntities: (
      state,
      action: PayloadAction<Array<HighlightedEntity>>
    ) => {
      state.highlightedEntities = action.payload;
    },
    resetLastAnnotation: (state) => {
      state.lastAnnotation = null;
    },
    addOrUpdateAnnotation: (
      state,
      action: PayloadAction<DocumentAnnotation>
    ) => {
      const { annotation, multipleGroupBlocks } = action.payload;
      const annotations = getAnnotationsInReducer(state, multipleGroupBlocks);

      const existingValuesJoined = (values: Array<string>) =>
        values.join(" ").toLowerCase();
      const newAnnotationValues = existingValuesJoined(annotation.values);

      let isUpdated = false;
      let isExisting = false;

      const updatedAnnotations = annotations.map((existingAnnotation) => {
        if (
          existingAnnotation.entity.id === annotation.entity.id &&
          existingAnnotation.index === annotation.index
        ) {
          isExisting = true;
          const existingAnnotationValues = existingValuesJoined(
            existingAnnotation.values
          );
          if (existingAnnotationValues !== newAnnotationValues) {
            isUpdated = true;
            return {
              ...existingAnnotation,
              values: annotation.values,
            };
          }
        }
        return existingAnnotation;
      });

      if (!isUpdated && !isExisting) {
        updatedAnnotations.push(annotation);
      }

      if (multipleGroupBlocks) {
        state.tableControlAnnotations = updatedAnnotations;
      } else {
        state.sideControlAnnotations = updatedAnnotations;
      }
    },
  },
});

export const {
  addAnnotationsForDocument,
  addAnnotationToState,
  updateAnnotationInState,
  updateAnnotationNormalizationInState,
  updateAnnotationsBatch,
  removeAnnotationFromState,
  removeAnnotationsForEntitiesByGroupBlockIndex,
  removeAllAnnotationsForDocument,
  updateTableAnnotationsInState,
  setTableAnnotations,
  changeScale,
  resetAnnotationReducer,
  updateGroupBlockAnnotations,
  removeAnnotationsForEntity,
  setIsLoadingAnnotation,
  setIsLoadingAnnotations,
  setIsDragging,
  setHighlightedEntities,
  resetLastAnnotation,
  addOrUpdateAnnotation,
} = annotationSlice.actions;

export default annotationSlice.reducer;
