import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getVariableFromEnv } from "../../common/utilities/env";
import { Document } from "../interfaces";
import { TextLayer } from "../../annotator/interfaces/textLayer";
import { DOCUMENT_MAX_TOKENS } from "../constants";
import { setTextLayer } from "../../annotator/store/AnnotatorSlice";
import {
  getHeadersWithAuthorization,
  parseReduxQueryError,
} from "../../common/reduxQuery/helpers";

const API_BASE_URL = getVariableFromEnv("API_BASE_URL");

export const documentApi = createApi({
  reducerPath: "documentApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_BASE_URL}/api/v1/documents`,
    prepareHeaders: (headers) => getHeadersWithAuthorization(headers),
  }),
  endpoints: (build) => ({
    getPdfDocument: build.query<ArrayBuffer, string>({
      query: (activeDocumentId) => ({
        url: `/${activeDocumentId}/pdf`,
        method: "GET",
        responseHandler: async (response) => await response.arrayBuffer(),
      }),
    }),
    getDocument: build.query<Document, string>({
      query: (documentId) => ({
        url: `/${documentId}`,
        method: "GET",
      }),
    }),
    fetchTextLayer: build.query<Array<TextLayer>, Document>({
      query: (activeDocument) => ({
        url: `/${activeDocument.id}/text-layer`,
        method: "GET",
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          const mapped = data.map((textLayer, index) => ({
            ...textLayer,
            shouldRender:
              index === 0 ||
              textLayer.textMapItems.length <= DOCUMENT_MAX_TOKENS,
          }));
          dispatch(setTextLayer(mapped));
        } catch (err: any) {
          console.error(
            `An error occurred while retrieving the metrics for the document sets: ${parseReduxQueryError(
              err
            )}`
          );
        }
      },
    }),
    fetchPdfText: build.query<
      ArrayBuffer,
      { documentId: string; page: number }
    >({
      query: ({ documentId, page }) => ({
        url: `/${documentId}/page/${page}/text`,
        method: "GET",
        responseHandler: async (response) => await response.arrayBuffer(),
      }),
    }),
    fetchPdfPageMetaData: build.query<
      ArrayBuffer,
      { documentId: string; page: number }
    >({
      query: ({ documentId, page }) => ({
        url: `/${documentId}/page/${page}/text-layer-lite`,
        method: "GET",
        responseHandler: async (response) => await response.arrayBuffer(),
      }),
    }),
    fetchPdfPageImage: build.query<
      ArrayBuffer,
      { documentId: string; page: number }
    >({
      query: ({ documentId, page }) => ({
        url: `/${documentId}/page/${page}/image`,
        method: "GET",
        responseHandler: async (response) => await response.arrayBuffer(),
      }),
    }),
  }),
});

export const {
  useLazyGetPdfDocumentQuery,
  useLazyGetDocumentQuery,
  useFetchTextLayerQuery,
  useLazyFetchPdfPageImageQuery,
  useLazyFetchPdfPageMetaDataQuery,
  useLazyFetchPdfTextQuery,
} = documentApi;
