import React, { FC } from "react";
import { Tooltip } from "antd";
import { getInLanguageOrDefault } from "../../../../../common/utilities/language";
import CategoryVisibleValue from "../../CategoryVisibleValue";
import SelectEmptyState from "../../SelectEmptyState";
import {
  CategorizationWithConfig,
  ICategory,
} from "../../../../../configMap/interfaces/category";
import LinkIcon from "../../LinkIcon";
import { EventTriggerType } from "../../../../interfaces/categorizationEvents";
import { Translation } from "../../../../../configMap";
import { OptionType } from "../../../../interfaces/classifier";
import CategorizationSelect from "../CategorizationSelect";
import { DebouncedFunc } from "lodash";

type Props = {
  categorizationWithConfig: CategorizationWithConfig;
  label: Translation;
  language: string;
  selectedCategory:
    | ICategory
    | { value: string | undefined; visibleValue: string | undefined }
    | null;
  isCategorizationDisabledByParent: boolean | undefined;
  disabledParentTooltip: string;
  disableTyping: boolean;
  disabled: boolean;
  isCategorizationLoading: boolean;
  categorizationOptions: Array<OptionType>;
  callEvents: (triggerType: EventTriggerType, value?: any) => void;
  handleChange: (key: string, callback: () => void) => Promise<void>;
  handleTypingChange: () => void;
  handleSearch: DebouncedFunc<(value: string) => Promise<void>>;
  handleFilter: (input: string, option: any) => any;
  status: "" | "warning" | "error";
  className?: string;
  tabId: string;
};

const CategorizationDynamicOrFixed: FC<Props> = ({
  categorizationWithConfig,
  label,
  language,
  selectedCategory,
  isCategorizationDisabledByParent,
  disabledParentTooltip,
  disableTyping,
  disabled,
  isCategorizationLoading,
  categorizationOptions,
  callEvents,
  handleChange,
  handleTypingChange,
  handleSearch,
  handleFilter,
  status,
  className,
  tabId,
}) => {
  return (
    <div className="classifier-item__container">
      <div className="classifier-item__data-wrapper">
        <LinkIcon categorization={categorizationWithConfig.categorization} />
        <div className="classifier-item__label-wrapper">
          <label>{`${getInLanguageOrDefault(label, language)} ${
            categorizationWithConfig.required ? "*" : ""
          }`}</label>
          <CategoryVisibleValue visibleValue={selectedCategory?.visibleValue} />
        </div>
      </div>
      <Tooltip
        title={isCategorizationDisabledByParent && disabledParentTooltip}
      >
        <CategorizationSelect
          open={disableTyping}
          value={selectedCategory?.value}
          handleChange={handleChange}
          callEvents={callEvents}
          onFocus={handleTypingChange}
          onBlur={handleTypingChange}
          onSearch={handleSearch}
          filterOption={handleFilter}
          notFoundContent={<SelectEmptyState />}
          disabled={disabled || isCategorizationDisabledByParent}
          tabId={tabId}
          categorizationOptions={categorizationOptions}
          loading={isCategorizationLoading}
          status={status}
          className={className}
        />
      </Tooltip>
    </div>
  );
};

export default CategorizationDynamicOrFixed;
